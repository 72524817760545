<template>
<div class = "row" style = "display:flex; flex-direction: row; width:100%;">
	
	<b-modal
		id="fullframe-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		hide-footer
	>	
		<FullFrameDisplay 
			style="height: 1000px; width: 500px;"
			:imageScale="2.7"	
			:framePath="currentFullFrameImage"
			:size="fullFramePathSizeMap[currentDetectionUuid]"
			:subFrame="currentFullFrameSubFrame"
		/>
	</b-modal>
	<div class="scatter">
		<div style="overflow:auto">
			<div class = "button-row">
				<div  style="float:left;width: 58px;">
					<input class="form-control" v-model="selectedBlock" type="text">
				</div>
				<div v-if="!taskUuid">
					<select style="border-radius: 5px; background-color: #A9BDBD;padding: 4px;height:100%;" v-model="currentLocalTrackList">
						<option v-if="localTrackLists" disabled value="">{{localTrackLists.length}} Datasets Available</option>
						<option v-for="dataset in localTrackLists" :key="dataset.tableName" :value="dataset.tableName">
							{{ dataset.tableName }}
						</option>
					</select>
				</div>
				<button v-if="(currentBlock>0)" class="btn btn-info" @click="loadPrevious(currentBlock)" >
					<i class="fas fa-arrow-left"></i>
				</button>
				<button v-if="showUnlabelled" class="btn btn-info" @click="retrieveUnlabelledTracks(selectedBlock)">
					{{selectedBlock}}
				</button>
				<button v-if="!showUnlabelled" class="btn btn-info" @click="loadBlockNum(selectedBlock)">
					{{selectedBlock}}
				</button>
				<button class="btn btn-info" @click="loadNext(currentBlock)">
					<i class="fas fa-arrow-right"></i>
				</button>
				<button v-if="comparisonDistances" class="btn btn-info" @click="finderToggle = !finderToggle">
					<i class="fas fa-search"></i>
				</button>
				
				
				<button v-if="(taskUuid !== '')"
					class="btn btn-info" @click="toggleSelectorButtons=!toggleSelectorButtons">
					<i class="fas fa-info"></i>
				</button>
				<button
					class="btn btn-info" @click="toggleMergeTrackEditor=!toggleMergeTrackEditor">
					<i class="fas fa-edit"></i>
				</button>
				<button class="btn btn-info" @click="toggleImageCardSize">
					<i :class="`fas fa-search-${size === 'xl' ? 'minus' : 'plus'}`"/>
				</button>
				<div v-if="taskUuid===''" style="margin-left:50px;margin-top:6px;">
					<input v-if="isAdmin" v-model="showUnlabelled" type="checkbox" class="custom-control-input" id="viewTypeToggle" checked="">
					<label v-if="showUnlabelled" class="custom-control-label" for="viewTypeToggle">Unlabelled View</label>
					<label v-if="!showUnlabelled" class="custom-control-label" for="viewTypeToggle">Labelled View</label>
				</div>

			</div>
			<div v-if="toggleSelectorButtons">
				<div class="card mb-3" style="margin-top:10px;width:50%;">
					<h3 class="card-header">Task Info</h3>

					<div class="card-body">

						<h5 v-if="!consolidateTaskUuid" class="card-title" >Pages assigned: {{allocatedBlocksFormatted}}</h5>

						<h5 v-else class="card-title">Consolidation Task
							<p>Please click and search ALL the images below and perform consolidation on any matches</p>

						</h5>
						<h5>Task ID: <code>{{(consolidateTaskUuid==='') ? taskUuid : consolidateTaskUuid}}</code> </h5>

						<br>
						<button class="btn btn-success" @click="markTaskAsDone()">Finish Task</button>
					</div>
				</div>
			</div>
			<div id="row1">
				<div class="column" id="explorer">
					<div id="controls" >
						<div id="recently_added">
							<div class="controls" id="recents">
								
								<div id="recently_added" v-if="(!dbToggled)" class="card border-light mb-3">
									<div class="card-header" >
										<!-- <pre v-if="this.taskUuid!==''" style="margin-left:5px;">Merges From Task {{this.taskUuid}}</pre> -->
										<pre style="margin-left:5px;">Merges</pre>
										<pre v-if="toggleMulti &&selectedFilteredBlock!=='all'" style="margin-left:5px;">{{globalMergeTracksFiltered.length}} Merge Tracks from page {{selectedFilteredBlock}} to page {{selectedFilteredBlockUpper}}</pre>
										<pre v-if="selectedFilteredBlock!=='all' &&!toggleMulti" style="margin-left:5px;">{{globalMergeTracksFiltered.length}} Merge Tracks from page {{selectedFilteredBlock}}</pre>
										<pre v-if="selectedFilteredBlock==='all'" style="margin-left:5px;">{{globalMergeTracksFiltered.length}} Merge Tracks identified in total</pre>

										<vs-button
											icon
											style="outline: none;  "
											color="success"
											v-if="loadingConsolidations===false && taskUuid ===''"
											@click="findInstancesConsolidation(currentSelectedMergeTrack)"

										>

											<i class="fas fa-search"></i>
										</vs-button>
										<vs-button
											icon
											style="outline: none;"
											v-else-if="loadingConsolidations===true && taskUuid ===''"
											color="success"
											loading
											@click="findInstancesConsolidation(currentSelectedMergeTrack)"

										>
											<i class="fas fa-search"></i>
										</vs-button>
										<template>
											<div v-if="consolidationCandidates.length>0">
												<pre>Page {{1+consolidationPage}} out of {{Math.ceil(totalConsolidationHelperResults/consolidationPageSize)}}  </pre>
												<div class = "row" style="display: flex; flex-direction: row; margin-left: 1px;">
													<vs-button
														icon
														style="outline: none;  "
														color="success"
														v-if="(loadingConsolidations===false) && (consolidationPage>0)"
														@click="consolidationPage = Math.max(--consolidationPage, 0);"
													>
														<i class="fas fa-arrow-left"></i>
													</vs-button>
													<vs-button
														icon
														style="outline: none;  "
														color="success"
														v-if="loadingConsolidations===false"
														@click="consolidationPage+=1;"
													>
														<i class="fas fa-arrow-right"></i>
													</vs-button>
												</div>
								
											</div>
										</template>
									</div>
									<div class="card-body">
										<h5 v-if="consolidationCandidates.length>0">Search Results</h5>
										<div v-if="!consolidateTaskUuid" class="custom-control custom-switch">
											<input v-model="toggleMulti" type="checkbox" class="custom-control-input" id="pageSelectType" checked="">
											<label v-if="!toggleMulti" class="custom-control-label" for="pageSelectType">Single Page Select</label>
											<label v-if="toggleMulti" class="custom-control-label" for="pageSelectType">Multi Page Select</label>
										</div>
										<pre v-if="!consolidateTaskUuid">Filter by page: </pre>
										<div v-if="!consolidateTaskUuid" class = "row"  style="display: flex; flex-direction: row; width:500px;margin-left: 0px;">
											<select  class="form-control" v-model="selectedFilteredBlock" style="width: 150px;">
												<option disabled value="">Please select one</option>
												<option v-if="!toggleMulti" v-for="minBlockNum in uniqueMergeBlocksForFilter" v-bind:key="minBlockNum">
													{{minBlockNum}}
												</option>
												<option v-if="toggleMulti" v-for="minBlockNum in uniqueMergeBlocksForFilter.filter(row=>row!=='all')" v-bind:key="minBlockNum">
													{{minBlockNum}}
												</option>
											</select>
											<select v-if="toggleMulti" class="form-control" v-model="selectedFilteredBlockUpper" style="width: 150px;">
												<option disabled value="">Please select one</option>
												<option v-for="minBlockNum in uniqueMergeBlocksForFilter.filter(row=>row!='all')" v-bind:key="minBlockNum">
													{{minBlockNum}}
												</option>
											</select>


										</div>
										<button v-if="(selectedMergeIndexes.size !== undefined && (selectedMergeIndexes.size > 0))" class="btn btn-danger"
											@click="clearSelected()">
											Clear
										</button>
										<button v-if="(selectedMergeIndexes.size >1)" class="btn btn-info btn-sm" style="display: block;padding: 0 10px;"
											@click="consolidateMergeTracks(Array.from(selectedMergeIndexes))">
											Consolidate {{selectedMergeIndexes.size}} Merge Tracks
										</button>
										
										<div id="recents">
											<div v-for="(uniquePerson, index) in consolidationCandidates
													.slice(consolidationPageSize*consolidationPage, consolidationPageSize*consolidationPage + consolidationPageSize)"
												:key="uniquePerson.mergeUuid" class="track-highlight"
												:class="{active: ((frameHoverIndex4 === index)), selected: selectedMergeIndexes.has(uniquePerson.mergeUuid)}"
												@click="mergeTrackClicked(index, $event, uniquePerson.mergeUuid);(frameHoverIndex4 === index) ? frameHoverIndex4 = -1 : null;"
												@mousedown="setCurrentDetectionUuid(uniquePerson.detectionUuid)"
												@mouseover="frameHoverAction4(index, uniquePerson.detectionUuid)"
												@mouseleave="(frameHoverIndex4 === index) ? frameHoverIndex4 = -1 : null">
												<button v-if="((selectedPointIndexes !== null) && (selectedPointIndexes.size>0)) && (frameHoverIndex2 === index)"
													class="btn btn-info btn-sm"
													style="display: block;position:absolute; z-index:9999;margin-left: 15px; margin-bottom: 4px;"
													@click="mergeRecentTracks(uniquePerson.mergeUuid)">
													<i class="fas fa-plus"></i>
													{{selectedPointIndexes.size}}
												</button>
												<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
													<img v-if="uniquePerson.subFrame===undefined && sizeMap[uniquePerson.detectionUuid]!==undefined" :src="uniquePeopleFramePathsMap.get(uniquePerson.detectionUuid)"
														:style="localSetClipCss(uniquePersonSubFramesMap.get(uniquePerson.detectionUuid), sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)">
													<img v-if="uniquePerson.subFrame!==undefined" id="recent-thumbnail"
														:src="getEntryThumbnail(uniquePerson)"
														:style="localSetClipCss(uniquePerson.subFrame, sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)"
														@click="setCurrentGlobalMerge(uniquePerson.mergeUuid);">
												</div>
											</div>
										</div>
										<br>
										<br>
										<div id="recents">
											<div v-for="(uniquePerson, index) in globalMergeTracksFiltered"
												:key="uniquePerson.mergeUuid" class="track-highlight"
												:class="{active: ((frameHoverIndex2 === index )), selected: selectedMergeIndexes.has(uniquePerson.mergeUuid)}"
												@click="mergeTrackClicked(index, $event, uniquePerson.mergeUuid);(frameHoverIndex2 === index) ? frameHoverIndex2 = -1 : null;"
												@mouseover="frameHoverAction2(index, uniquePerson.detectionUuid)"
												@mousedown="setCurrentDetectionUuid(uniquePerson.detectionUuid)"
												@mouseleave="(frameHoverIndex2 === index) ? frameHoverIndex2 = -1 : null">
												<button v-if="((selectedPointIndexes !== null) && (selectedPointIndexes.size>0)) && (frameHoverIndex2 === index)"
													class="btn btn-info btn-sm"
													style="display: block;position:absolute; z-index:9999;margin-left: 15px; margin-bottom: 4px;"
													@click="mergeRecentTracks(uniquePerson.mergeUuid)">
													<i class="fas fa-plus"></i>
													{{selectedPointIndexes.size}}
												</button>
												<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
													<img 
														v-if="uniquePerson.subFrame===undefined && sizeMap[uniquePerson.detectionUuid]!==undefined" 
														:src="uniquePeopleFramePathsMap.get(uniquePerson.detectionUuid)"
														:style="localSetClipCss(uniquePersonSubFramesMap.get(uniquePerson.detectionUuid),  sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)">
													<img v-if="uniquePerson.subFrame!==undefined" id="recent-thumbnail"
														:src="getEntryThumbnail(uniquePerson)"
														:style="localSetClipCss(uniquePerson.subFrame, sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)"
														@click="setCurrentGlobalMerge(uniquePerson.mergeUuid); setCurrentDetectionUuid(uniquePerson.detectionUuid)">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="button-controls" v-if ="availablePoints.length>0">
								<button v-if="selectedPointIndexes" class="btn btn-info" @click="mergeTracks()">
									Merge {{selectedPointIndexes.size}} Tracks
								</button>
								

								<button  class="btn btn-info" @click="clearSelected()">
									Clear
								</button>
								<button   class="btn btn-danger" @click="moveToGarbage({type: 'garbage'})" style="background-color: #FF0000;">
									<i class="fas fa-trash"></i>
								</button>
								<button   class="btn btn-danger" @click="moveToGarbage({type: 'staff'})" style="background-color: #FF0000;">
									<i class="fas fa-user"></i>
								</button>
							</div>
						</div>

						<div class = "row" style="display: flex; flex-direction: row; width: 100%;" v-if="(availablePoints)">

							<div v-if="(availablePoints.length>0)" id="tracks" :style="toggleMergeTrackEditorStyle()">
								<div v-for="(line, index) in displaySelected(frameHoverIndex)" :key="`summary-${index}`">
									{{ line }}
								</div>

								<div v-if="(framePathsMap !== null && subFramesMap !== null)">

									<div class="track-data" >
										<div v-for="(startPoint, index) in availablePoints"
											:key="startPoint.detectionUuid"
											:style="setTrackStyle(index)" class="track-highlight"
											style="position:relative;" 
											:class="setTrackClass(index,frameHoverIndex)"
											:data-uuid="startPoint.detectionUuid"
											@mouseover="frameHoverAction(index, startPoint.detectionUuid,$event);shiftKey = $event.shiftKey;clickEngaged = ($event.which===1)"
											@mouseleave="(frameHoverIndex === index) ? frameHoverIndex = -1 : null"
											@mousedown="frameClicked(index, $event); (frameHoverIndex === index) ? frameHoverIndex = -1 : null; setCurrentDetectionUuid(startPoint.detectionUuid)"
											@mouseup="selectedByDrag(index, $event);"
										>
											
											<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
												<img
													
													v-if="sizeMap[startPoint.detectionUuid]!==undefined"
													draggable="false"
													ondragstart="return false;"
													:src="framePathsMap.get(startPoint.detectionUuid)"
													:style="localSetClipCss(subFramesMap.get(startPoint.detectionUuid), sizeMap[startPoint.detectionUuid].width, sizeMap[startPoint.detectionUuid].height)"
												>
												
											</div>
											
											
										</div>
									</div>
								</div>
							</div>
							<MergeTrackEditor
								v-if="toggleMergeTrackEditor"
								:db="this.db"
								:inputTable="this.localTrackListComputed"
								:mergeUuid="this.currentSelectedMergeTrack"
								:key="this.currentSelectedMergeTrack"
								:resolution="this.resolution"
								:format="this.format"
								:currPage="this.currentBlock"
								@mergeUuidUpdateEvent="mergeUuidUpdateEvent"
								@updateCurrentMergeTrack="updateCurrentMergeTrack"
								@imageHover="mergeEditorHoverEvent"
								@retrieveTrackEndPoints="retrieveTrackEndPoints"
								ref="mergeEditor"
								:style="mergeTrackEditorStyle()"
								:mode="(consolidateTaskUuid !== '') ? 'read' : 'read/write'"
								:portalDetails="portalDetails"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<vue-context ref="menu">
		<li>
			<a href="#" @click="showFullFrame()" @click.prevent="onClick($event.target.innerText)">View Full Frame</a>
		</li>
	</vue-context>
</div>

</template>

<script>

import VueContext from 'vue-context';


import { BFormInput } from 'bootstrap-vue';

Vue.component('b-form-input', BFormInput)

import { BOverlay } from 'bootstrap-vue'
Vue.component('b-overlay', BOverlay)
Vue.component("vue-context", VueContext)
import {
	BModal
} from 'bootstrap-vue';

Vue.component('b-modal', BModal)


import Vue from 'vue'

import { dataAPI } from "../../http-common";
import { setClipCss, createImageSizeMap } from '../../utils/images.js';


import MergeTrackEditor from '../MergeTrackEditor.vue';

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles


Vue.use(Vuesax, {
	// options here
})
import { vsButton, vsSelect, vsPopup, vsPagination } from 'vuesax';


import FullFrameDisplay from './FullFrameDisplay.vue';

export default {
	name: 'TrackScatterPlot',
	components: {
		VueContext,
		MergeTrackEditor,
		FullFrameDisplay,
		BModal
	},
	props: {
		taskUuid: { type: String, default: ''},
		external: { type: Boolean, default: false},
		consolidateTaskUuid: { type: String, default: ''},
	},
	data: function() {
		return {
			toggleSelectorButtons:  (this.consolidateTaskUuid) ? true : false,
			currentSelectedGlobalTrack: null,
			currentSelectedLocalTrack: null,
			currentSelectedMergeTrack: null,
			recentsToggled: true,
			dbToggled: true,
			availablePoints: [],
			selectedFilteredBlockUpper: null,
			distanceThreshold: 0,
			debugData: false,
			fixedFrameCropWidth: 150,
			toggleMergeTrackEditor: false,
			frameHoverIndex: -1,
			frameHoverIndex2: -1,
			frameHoverIndex3: -1,
			selectedFilteredBlock: null,
			lastSelectedPointIndex: -1,
			lastSelectedResultIndex: -1,
			lastSelectedMergeIndex: -1,
			allocatedBlocks: [],
			currentImage: null,
			currentDetectionUuid: null,
			currentSubFrame: null,
			framePathsMap: null,
			recentlyMerged: [],
			points: [],
			globalMergedTracks: null,
			localTrackLists: null,
			currentLocalTrackList: '',
			selectedPointIndexes: null,
			selectedMergeIndexes: null,
			stylePointer: null,
			subFramesMap: null,
			uniquePersonSubFramesMap: null,
			uniquePeopleFramePathsMap: null,
			username: '',
			pageSize: 100,
			currentBlock: null,
			selectedBlock: null,
			preprocessedVisitTable: null,
			localTrackMinMaxTime: null,
			distinctBlocks: null,
			advancedActive: false,
			advancedUsers : ["jcoppe", "jonas", "kokkonen"],
			mergeUuidsToConsolidate: null,
			blockTypes: ["minute", "hour"],
			selectedBlockType: null,
			fullscreen: false,
			mapToggle: false,
			toggleMulti: false,
			recentCreatedMergeUuid: '',
			fullFrameContainerClass: false,
			frameWidth: "300px",
			frameMarginBottom: '0px',
			reidTaskProgress: null,
			comparisonDistances: null,
			topMatches: null,
			queryEntry: null,
			candidates: null,
			dataLoaded: false,
			finderToggle: false,
			selectedCandidates: new Set(),
			selectedFromResult: new Set(),
			candidatesDistance: null,
			log: "",
			db: "",
			loadMap: new Map(),
			pointsMerged: [],
			highResDatasetsDate: '2020-06-12',
			clickEngaged: false,
			shiftKey: false,
			consolidationCandidates: [],
			frameHoverIndex4: null,
			loadingConsolidations: false,
			consolidationResultLimit: 0,
			consolidationPage: 0,
			consolidationPageSize:  25,
			mergesRequiringConsolidation: [],
			showUnlabelled: false,
			isReduced: 0,
			sizeMap: {},
			size: 'xs',
			fullFramePathSizeMap: {},
			currentFullFrameSubFrame: {},
			currentFullFrameImage: null,
			portalDetails: null,
		}
	},
	computed: {
		resolution: function(){
			return 1920;
		},
		format: function(){
			if(this.taskUuid === ''){
				if(this.currentLocalTrackList.replace(/-/g,'') === '20200629'){
					return 'webp';
				}
				else{
					return 'jpeg';
				}
			}
			else{
				if(this.currentLocalTrackList.replace(/reidlocaltracks/g,'')==='20200629'){
					return 'webp';
				}
				else{
					return 'jpeg';
				}
			}
		},
		startPoints: function() {
			return this.points.filter(point => point.isStart === 1);
		},
		detectionClusterMap: function(){
			let groupBySimple = function(xs, key) {
				return xs.reduce(function(rv, x) {
					(rv[x[key]] = rv[x[key]] || []).push(x);
					return rv;
				}, {});
			};
			let detectionsHiddenIncsluter = groupBySimple(this.points, 'clusterUuid');
			return detectionsHiddenIncsluter;

		},
		finishPoints: function() {
			return this.points.filter(point => point.isStart === 0);
		},
		allLoaded: function(){
			return ((this.availablePoints.length > 0));
		},
		deadFrames: function(){
			return (this.availablePoints.filter(p => (this.framePathsMap.get(p.detectionUuid) === undefined)))
		},
		selectedItemsSize: function(){
			if(this.finderToggle === false){
				return this.selectedPointIndexes.size;
			}
			else{
				return this.selectedCandidates.size;
			}
		},
		uniqueMergeBlocksForFilter: function(){
			let blocks=[];
			const unique = (value, index, self) => {
				return self.indexOf(value) === index
			}


			blocks = this.globalMergedTracks.map(row => ""+row.minBlockNum);


			let distinctBlocks = blocks.filter(unique).sort(function(a, b) {
				return parseInt(a) - parseInt(b);
			});


			if(this.taskUuid!==''){
				distinctBlocks = distinctBlocks.filter(row=>this.allocatedBlocks.includes(row))
			}

			distinctBlocks.push('all');

			return distinctBlocks;
		},
		filteredGlobalMergeBlockNum: function(){
			if(this.selectedFilteredBlock === null){
				return this.currentBlock;
			}
			else{
				return this.selectedFilteredBlock;
			}
		},
		filteredMergeBlockNumRange: function(){
			if(this.toggleMulti){
				return [parseInt(this.filteredGlobalMergeBlockNum), parseInt(this.selectedFilteredBlockUpper)]
			}
			else{
				return [parseInt(this.filteredGlobalMergeBlockNum), parseInt(this.filteredGlobalMergeBlockNum)];
			}
		},
		globalMergeTracksFiltered: function(){

			if(this.taskUuid===''){
				if (this.selectedFilteredBlock === "all"){
					return this.globalMergedTracks;
				}
				else{
					if((this.selectedFilteredBlockUpper!==null)){
						return this.globalMergedTracks
							.filter(uniquePerson => parseInt(uniquePerson.minBlockNum) >= parseInt(this.filteredGlobalMergeBlockNum))
							.filter(uniquePerson => parseInt(uniquePerson.minBlockNum) <= parseInt(this.selectedFilteredBlockUpper));
					}
					else{
						return this.globalMergedTracks.filter(uniquePerson => parseInt(uniquePerson.minBlockNum) === parseInt(this.filteredGlobalMergeBlockNum))
							.sort((a, b) => ((new Date(a.minInsert)).getTime() > (new Date(b.minInsert)).getTime()) ? 1 : -1);
					}

				}
			}
			// if we are in a task, display  merges from this task uuid
			else{
				if(!this.toggleMulti){
					if (this.selectedFilteredBlock === "all"){
						return this.globalMergedTracks.filter(p=>(p['taskUuid']===this.taskUuid) || (this.mergesRequiringConsolidation.includes(p.mergeUuid)))
							.sort((a, b) => ((new Date(a.minInsert)).getTime() > (new Date(b.minInsert)).getTime()) ? 1 : -1);
					}
					return this.globalMergedTracks.filter(uniquePerson => parseInt(uniquePerson.minBlockNum) === parseInt(this.filteredGlobalMergeBlockNum))
						.filter(p=>(p['taskUuid']===this.taskUuid) || (this.mergesRequiringConsolidation.includes(p.mergeUuid)))
						.sort((a, b) => ((new Date(a.minInsert)).getTime() > (new Date(b.minInsert)).getTime()) ? 1 : -1);
				}
				else{
					return this.globalMergedTracks
						.filter(uniquePerson => parseInt(uniquePerson.minBlockNum) >= parseInt(this.filteredGlobalMergeBlockNum))
						.filter(uniquePerson => parseInt(uniquePerson.minBlockNum) <= parseInt(this.selectedFilteredBlockUpper))
						.filter(p=>(p['taskUuid']===this.taskUuid) || (this.mergesRequiringConsolidation.includes(p.mergeUuid)))
						.sort((a, b) => ((new Date(a.minInsert)).getTime() > (new Date(b.minInsert)).getTime()) ? 1 : -1);
				}
			}
		},
		bestDetectionUuid: function(obj){
			if(obj.bestDetectionUuid !== undefined){
				return obj.bestDetectionUuid;
			}
			else{
				return obj.detectionUuid;
			}
		},
		localTrackListComputed: function(){
			return this.currentLocalTrackList
		},
		allocatedBlocksFormatted(){
			return this.allocatedBlocks.map(pageNum=>parseInt(pageNum)).toString();
		},
		myMerges(){

		},
		totalConsolidationHelperResults(){
			return this.consolidationCandidates.length
			// return this.globalMergedTracks.filter(g=>this.consolidationCandidates

			// 					.map(r=>r.mergeUuid)
			// 					.includes(g.mergeUuid))
			// 					.sort((a, b) => {
			// 						return this.consolidationCandidates
			// 							.map(r=>r['mergeUuid'])
			// 							.indexOf(a['mergeUuid']) - this.consolidationCandidates
			// 							.map(r=>r['mergeUuid']).indexOf(b['mergeUuid'])
			// 					}).length
		},
		isAdmin(){
			return (localStorage.getItem('is_admin') === 'true');
		},
		imageCardSize(){
			
			if(this.size === 'xl'){
				const width = 120;
				return {
					fixedCropWidth: width,
					css: `width: ${width}px; height: ${width*1.7}px;`
				};
			}
			else{
				const width = 75;
				return {
					fixedCropWidth: 75,
					css: `width: ${width}px;`
				};
			}
		},
	},
	watch: {
		points: function() {
			if (this.points.length > 0) {
				this.retrieveDetectionSubFrames();
				this.retrieveFullFrames();
				this.availablePoints = this.startPoints;
				this.selectedPointIndexes = new Set();
			}
		},
		globalMergedTracks: function(){
			if(this.globalMergedTracks){
				this.selectedMergeIndexes = new Set();
				this.dbToggled=false;
			}
		},
		async currentLocalTrackList(newVal){
			if(this.taskUuid === ''){
				const { dbName } = this.localTrackLists.filter(r=>r.tableName === newVal)[0];
				this.db = dbName;

				const { data } = await dataAPI.post(`${this.db}/datasets/getDataset`, {localTrackList: newVal})
				const { reduced, hasCroppedFrames, datasetConfig } = data;
				
				// portal details may not be available (old legacy KS datasets)
				if(datasetConfig?.portal_ks_config){
					const { portal_ks_config: {dataset_name: portalDatasetName, deployment_code: portalDeploymentCode } } = datasetConfig;
					this.portalDetails = { datasetName: portalDatasetName, deploymentCode: portalDeploymentCode };
				}
				
				this.isReduced =  reduced;
				this.hasPreCroppedFrames = hasCroppedFrames;

				this.retrieveGlobalMergedTracks(this.currentLocalTrackList);

				if(this.consolidateTaskUuid === ''){
					this.selectedFilteredBlock = 0
				}
			}
		},
		filteredMergeBlockNumRange([lower, upper]){
			const data = this.globalMergedTracks;
			this.retrieveGloballyMergedThumbnails(data.filter(r => parseInt(r.minBlockNum) >= lower && parseInt(r.minBlockNum) <= upper));
		}
	},
	beforeDestroy() {
		window.removeEventListener('mousemove', this.onMouseMove);
		window.removeEventListener('keydown', this.keyPressEventHandler);
	},
	mounted: function() {
		
		this.dataLoaded=false;
		this.retrieveActiveReidDatasets();
		window.addEventListener('keydown', this.keyPressEventHandler, false);
		window.addEventListener('mousemove', this.onMouseMove);

		// Retrieve username
		if (localStorage.getItem('username') !== undefined) {
			this.username = localStorage.getItem('username');
		}
		if(this.taskUuid){
			// get task info for this specific user
			const url = `/getTaskInfo/${this.taskUuid}`;
			dataAPI
				.get(url)
				.then(async response => {
					this.db = response.data.databaseName.split("_")[0];
					const { data } = await dataAPI.post(`${this.db}/datasets/getDataset`, {localTrackList: response.data['tableName']})

					const { reduced, hasCroppedFrames, datasetConfig } = data;
					
					this.isReduced = reduced;
					if(datasetConfig?.portal_ks_config){
						const {deployment_code: portalDeploymentCode, dataset_name: portalDatasetName } = datasetConfig.portal_ks_config;
						this.portalDetails = {
							deploymentCode: portalDeploymentCode,
							datasetName: portalDatasetName
						}
					}
					this.hasPreCroppedFrames = hasCroppedFrames;
					this.currentLocalTrackList = response.data['tableName'];
					
					await this.retrieveGlobalMergedTracks(this.currentLocalTrackList);

					this.allocatedBlocks = response.data['assignedBlocks'].split(',');
					this.currentBlock = response.data['assignedBlocks'].split(',')[0];
					this.selectedBlock = this.currentBlock;
					this.loadBlockNum(this.selectedBlock);

				})
				.catch(e=>{
					console.log(e);
				});
		}
		if(this.consolidateTaskUuid !== ''){
			this.selectedFilteredBlock = 'all'
		}
	},
	methods: {
		setTrackClass(index, frameHoverIndex){

			let shiftSelectRangeStart = Math.min(this.lastSelectedPointIndex, frameHoverIndex);
			let shiftSelectRangeEnd = Math.max(this.lastSelectedPointIndex, frameHoverIndex);
			let shiftRangeLength = Math.abs(this.lastSelectedPointIndex-frameHoverIndex);

			let shiftIndexes = (this.lastSelectedPointIndex < frameHoverIndex) ? [...Array(shiftRangeLength).keys()].map(r=>r + shiftSelectRangeStart+1):[...Array(shiftRangeLength).keys()].map(r=>r + shiftSelectRangeStart)

			// console.log("shiftIndexes", shiftIndexes)
			// console.log("shiftIndexes _ shiftSelectRangeStart", shiftSelectRangeStart)
			// console.log("shiftIndexes _ shiftSelectRangeEnd", shiftSelectRangeEnd)
			// console.log("shiftIndexes _ frameHoverIndex",  frameHoverIndex)

			if((this.clickEngaged === true)||(this.shiftKey===true)){
				return {active: (shiftSelectRangeStart !== -1) && shiftIndexes.includes(index) , selected: this.selectedPointIndexes.has(index) }
			}
			else{
				return {active: frameHoverIndex === index, selected: this.selectedPointIndexes.has(index)}
			}

		},
		updateCurrentMergeTrack(newVal){
			this.currentSelectedMergeTrack = newVal;
		},
		mergeUuidUpdateEvent(oldVal, newVal){
			const indexToUpdate = this.globalMergedTracks.findIndex(r=>r.mergeUuid === oldVal);
			console.log('index', indexToUpdate)
			this.globalMergedTracks[indexToUpdate].mergeUuid = newVal;
		},
		selectedByDrag(frameIndex, event){
			if((this.frameHoverIndex === -1)){
				// do nothing
			}
			else{
				if (event.which === 1 && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex < frameIndex) {
					// console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
					for (let i = this.lastSelectedPointIndex + 1; i <= frameIndex; i++) {
						this.selectedPointIndexes.add(i);
					}
					this.lastSelectedPointIndex = frameIndex;
				}
				else if (event.which === 1 && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex > frameIndex) {
					// console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
					for (let i = frameIndex; i <= this.lastSelectedPointIndex; i++) {
						this.selectedPointIndexes.add(i);
					}
					this.lastSelectedPointIndex = frameIndex;
				}
			}
		},
		async findInstancesConsolidation(mergeUuid){
			try{
				this.loadingConsolidations = true;
				this.consolidationPage=0;

				let { data: candidateGallery } = await dataAPI.post(`${this.db}/feature/${this.currentLocalTrackList}/getPairWiseMap`, { mergeUuid });

				const detections = candidateGallery.map(r => {return {detectionUuid: r.detectionUuid}});
				
				const framesAndSubframesForGallery = await this.retrieveFramePaths(detections, this.hasPreCroppedFrames, this.currentLocalTrackList, true);
				
				const detectionToFrameMap = framesAndSubframesForGallery.reduce((obj, xs) => {
					obj[xs.detectionUuid] = {
						frame: xs.frame,
						minimumY: xs.minimumY,
						maximumX: xs.maximumX,
						maximumY: xs.maximumY,
						minimumX: xs.minimumX,
					};
					return obj;
				}, {});

				candidateGallery = candidateGallery.map(candidate => { 
					return {
						distance: candidate.distance,
						detectionUuid: candidate.detectionUuid,
						mergeUuid: candidate.mergeUuid,
						framePath: detectionToFrameMap[candidate.detectionUuid].frame,
						minimumY: detectionToFrameMap[candidate.detectionUuid].minimumY,
						maximumX: detectionToFrameMap[candidate.detectionUuid].maximumX,
						maximumY: detectionToFrameMap[candidate.detectionUuid].maximumY,
						minimumX: detectionToFrameMap[candidate.detectionUuid].minimumX
					}
				});

				this.consolidationCandidates = candidateGallery;
				this.consolidationCandidates[0] = this.globalMergedTracks.filter(r => r.mergeUuid === mergeUuid)
					.map(r => {
						return {
							detectionUuid: r.detectionUuid,
							mergeUuid: r.mergeUuid,
						}
					})[0]

				this.consolidationCandidates.slice(1, this.consolidationCandidates.length).forEach(candidatePoint => {
					this.uniquePeopleFramePathsMap.set(candidatePoint.detectionUuid, candidatePoint.framePath);
					this.uniquePersonSubFramesMap.set(candidatePoint.detectionUuid,
						{
							top: candidatePoint.minimumY,
							right: candidatePoint.maximumX,
							bottom: candidatePoint.maximumY,
							left: candidatePoint.minimumX
						})
				})

				const pathMapRequiringSize = this.consolidationCandidates.slice(1, this.consolidationCandidates.length).reduce((obj, xs) => {
					obj[xs.detectionUuid] = xs.framePath;
					obj.set(xs.detectionUuid, xs.framePath);
					return obj;
				}, new Map());

				const sizeMap = await createImageSizeMap(pathMapRequiringSize);

				this.sizeMap = {...this.sizeMap, ...sizeMap};
				this.loadingConsolidations = false;
			}
			catch(error){
				console.error('In function: findInstancesConsolidation', error);
				this.loadingConsolidations = false;
				this.$noty.error(`Error: Failed to retrieve search results!`, {layout: "bottomLeft"})
			}
		},
		mergeTrackEditorStyle(){
			if(this.availablePoints.length>0){
				return "margin-left: 15px; width: 48%"
			}
			else{
				return "margin-left: 15px; width: 100%"
			}
		},
		toggleMergeTrackEditorStyle(){
			if(this.toggleMergeTrackEditor){
				if(this.availablePoints.length>0){return 'width: 48%';}
				else{return 'width: 100%';}
			}else{
				return '';
			}

		},
		formatDate: function(string){
			const year = string.substring(0,4);
			const month = string.substring(4,6);
			const day = string.substring(6,8);
			return `${year}-${month}-${day}`
		},
		optionClicked (event) {
			window.alert(JSON.stringify(event))
		},
		markTaskAsDone(){
			let confirm = window.confirm(`Are you sure you would to complete the task ${this.taskUuid}?`);
			if(confirm){
				let taskUuid = this.taskUuid;

				if(this.availablePoints.length === 0) {
					const url = `/updateTask/`;
					dataAPI.post(url, {taskUuid: this.taskUuid, status: "pendingApproval", taskType: 'reid', tableName: this.currentLocalTrackList, mergesFromTask: this.globalMergeTracksFiltered.length}).then(result=>{
						const nextUrl = window.location.href.split('/')[0]+"/"+window.location.href.split('/')[1];

						window.location.replace(`${nextUrl}`+'dashboard');
					})
						.catch(error=>{console.log(error)})
				}
				else{
					this.$noty.error(`You still have ${this.availablePoints.length} images to process!`, {layout: 'bottomLeft'});
				}


			}

		},
		keyPressEventHandler: function(event){

			if((event.key === "M")){
				this.mergeTracks()
			}
			if ((event.key === "g")){
				if(this.frameWidth === "700px"){
					this.frameWidth = '300px';
				} else if(this.frameWidth === "300px"){
					this.frameWidth = '700px';
				}
			}

		},
		clearResults(){
			this.queryEntry = null;
			this.candidates = null;
			this.finderToggle=false;
			this.selectedPointIndexes = new Set();
			this.selectedCandidates = new Set();
		},
		findAllInstances(optionalObj){
			let queryEntry = [];
			const DISTANCE_THRESHOLD = parseInt(this.distanceThreshold)/100;
			// console.log("DISTANCE_THRESHOLD", DISTANCE_THRESHOLD)
			//const DISTANCE_THRESHOLD = 0.75;
			if(optionalObj === undefined){
				this.availablePoints.forEach((point, index) => {
					if (this.selectedPointIndexes.has(index)) {
						queryEntry.push({
							detectionUuid: point.detectionUuid,
							framePath: this.framePathsMap.get(point.detectionUuid),
							subFrame: this.subFramesMap.get(point.detectionUuid)
						});
					}
					this.queryEntry = queryEntry;
				});
			}else{
				queryEntry.push(optionalObj);
				this.queryEntry = [optionalObj]
			}
			const distances = this.comparisonDistances[queryEntry[0].detectionUuid];

			const distanceKeys = Object.keys(distances);
			const distanceValues = Object.values(distances);

			let distancesObjectList = []


			// reordering the python output to be able to be sortable by javascript

			for(let i = 0; i <= distanceKeys.length; i++){
				distancesObjectList.push({
					detectionUuid: distanceKeys[i],
					distance: distanceValues[i]
				})
			}

			const candidatesDistance = distancesObjectList
				.sort((a, b) => (a.distance > b.distance) ? 1 : -1)
				.filter(item => item.distance <= DISTANCE_THRESHOLD)
				.map(candidate => candidate.distance)
				.filter(c => this.availablePoints.map(p=>p.detectionUuid).includes(c))


			const candidates = distancesObjectList
				.sort((a, b) => (a.distance > b.distance) ? 1 : -1)
				.filter(item => item.distance <= DISTANCE_THRESHOLD)
				.map(candidate => candidate.detectionUuid)
				.filter(c => this.availablePoints.map(p=>p.detectionUuid).includes(c))
			// console.log(candidates);
			this.candidates = candidates;
			this.candidatesDistance = candidatesDistance;
			// console.log("candidates distance: ", distancesObjectList.sort((a, b) => (a.distance > b.distance) ? 1 : -1).filter(item => item.distance <= DISTANCE_THRESHOLD));
			this.clearSelected();

		},
		getFrameNumberFromUuid(detectionUuid, $event){
			let index = 0
			this.availablePoints.forEach(item =>{

				if (detectionUuid === item.detectionUuid){

					console.log(`detectionUuid ${detectionUuid} found @ index ${index}!`)
					return index;

				}
				index += 1
			})
		},
		retrieveReidLabellingProgress() {
			const localTrackList = `reidLocalTracks${this.currentLocalTrackList.replace(/-/g,'')}`;
			const url = `/${this.db}/getReidLabellingProgress/${localTrackList}`;

			dataAPI
				.get(url)
				.then(response => {

					this.reidTaskProgress =  response.data[0];
				})
				.catch(e => {
					console.log(e);
					this.reidTaskProgress = null;
				});
		},
		retrieveAllTasksFromDatabase(selectedDb) {
			const url = `/${selectedDb}/getTasks/`;
			dataAPI
				.get(url)
				.then(response => {
					this.tasks = response.data;
				})
				.catch(error => {
					console.log(error);
				});
		},
		toggle () {
			this.$fullscreen.toggle(this.$el.querySelector('.ff-canvas_fullframe_preview'), {
				wrap: false,
				callback: this.fullscreenChange
			})
		},
		fullscreenChange (fullscreen) {
			this.fullscreen = fullscreen
		},
		clearSelected: function() {
			this.lastSelectedPointIndex = -1,
			this.selectedPointIndexes = new Set();

			this.lastSelectedResultIndex = -1;
			this.selectedFromResult = new Set();
			this.selectedCandidates = new Set();

			this.lastSelectedMergeIndex = -1,
			this.selectedMergeIndexes = new Set();
		},
		consolidateMergeTracks: function(selectedMergeIndexes){
			const confirm = window.confirm('Confirm you have reviewed the merge track editor for each merge you are consolidating. Press esc key to cancel, enter key to proceed.');
			if(confirm){
				const url = `/${this.db}/consolidate`;
				const consolidateEntries = selectedMergeIndexes;
				const dataset = this.currentLocalTrackList;
				let representativeMergeUuid = consolidateEntries[0];
				let representativeMergeUuidObject = this.globalMergedTracks.filter(item => item['mergeUuid'] === representativeMergeUuid);
				const firstPos = this.globalMergedTracks.findIndex(uuid=> uuid['mergeUuid']===selectedMergeIndexes[0]);
				const body = {consolidateEntries: consolidateEntries, dataset:dataset};

				dataAPI.post(url, body)
					.then(response => {
						// If error detected in consolidation, tells user to try again
						if(response.data.state === 'rejected'){
							this.$noty.error('A reviewer has previously detected errors in your consolidation, check the merge track editor for errors and try a different selecteion of merges to consolidate',{layout: 'bottomLeft'})
						}
						else{
							this.$noty.success('Consolidation accepted!',{layout: 'bottomLeft'})
							this.currentSelectedMergeTrack = response.data;
							this.globalMergedTracks =  this.globalMergedTracks.filter(item => !consolidateEntries.includes(item['mergeUuid']));
							representativeMergeUuidObject[0]['mergeUuid'] = response.data;
							this.globalMergedTracks.splice(firstPos, 0, representativeMergeUuidObject[0]);
							this.consolidationCandidates = this.consolidationCandidates.filter(r=>!consolidateEntries.includes(r['mergeUuid']));
						}
					})
					.catch(e => {
						console.log(e);
					});
			}
			else{
				return;
			}
		},
		calculateReidProgress() {
			const remaining = this.reidTaskProgress['localTracksRemaining']
			const total = this.reidTaskProgress['total']
			return (100 * (1 - remaining / total)).toFixed(2);
		},
		moveToGarbage: function(inputObj) {
			let type = inputObj['type'];
			let confirm = window.confirm('Are you sure?');
			if(confirm){
				// moves local tracks to a garbage table - for junk detections
				let newAvailablePoints = [];
				let mergeEntries = [];

				this.availablePoints.forEach((point, index) => {
					if (this.selectedPointIndexes.has(index)) {
						mergeEntries.push({
							detectionUuid: point.detectionUuid,
							localTrackUuid: point.localTrackUuid,
							trackUuid: point.trackUuid
						});
					} else {
						newAvailablePoints.push(point);
					}
				});
				this.availablePoints = newAvailablePoints;
				const url = `/${this.db}/garbage`;

				const body = {
					mergeEntries: mergeEntries,
					type: type,
					dataset: this.currentLocalTrackList,
					taskUuid: this.taskUuid || undefined,
				};
				dataAPI
					.post(url, body)
					.then(response => {
						// console.log(response.data);
						this.$socket.emit('dataEvent', `${this.username} is moving ${mergeEntries.length} local tracks to garbage`);
					})
					.catch(e => {
						console.log(e);
						this.pushFailNotification("Merge event failed! Please check the logs");
					});
				this.clearSelected();
			}
		},
		createFramePathMap: function(rawData) {
			let _framePathsMap = new Map();
			rawData.forEach(path => _framePathsMap.set(path.detectionUuid, path.frame));
			if (this.recentlyMerged !== null){
				this.recentlyMerged.forEach(entry => {
					_framePathsMap.set(entry.firstDetectionUuid, entry.thumbNail);
				});
			}

			return _framePathsMap;
		},
		createVisitTable: function() {
			dataAPI
				.post(`/${this.db}/visitTrack`, {startAndEndTable: this.preprocessedVisitTable})
				.then(response => {
					console.log(`Table Sent!`);
				})
				.catch(e => {
					console.log(e);
					console.log(`Table Not Sent!`);
				});
		},
		createSubFramesMap: function(rawData) {
			console.log("rawData", rawData)
			let _subFramesMap = new Map();
			rawData
				.filter(subFrame => subFrame.label === 'person' && subFrame.textureLabel === 'rgb')
				.forEach(subFrame => _subFramesMap.set(subFrame.detectionUuid, {
					top: subFrame.minimumY,
					right: subFrame.maximumX,
					bottom: subFrame.maximumY,
					left: subFrame.minimumX,
				}));

			if (this.recentlyMerged !== null){
				this.recentlyMerged.forEach(entry => {
					_subFramesMap.set(entry.firstDetectionUuid, entry.subFrame);
				});
			}
			console.log("subframes map finished:", _subFramesMap);
			return _subFramesMap;
		},
		displaySelected: function(_frameHoverIndex) {

			if (_frameHoverIndex < 0 || this.debugData === false) {
				return '';
			}
			const indexPair = this.getTrackIndexPair(_frameHoverIndex);
			const startPoint = this.points[indexPair[0]];
			const endPoint = this.points[indexPair[1]];
			let output = [`TrackUuid: ${startPoint.trackUuid}`]
			if (startPoint.localTrackUuid !== undefined) {
				output.push(`LocalTrackUuid: ${startPoint.localTrackUuid}`);
			}
			output.push(`Start DetectionUuid: ${startPoint.detectionUuid}`);
			output.push(`Finish DetectionUuid: ${endPoint.detectionUuid}`);
			output.push(`Start Frame Path: ${this.framePathsMap.get(startPoint.detectionUuid)}`);
			output.push(`Finish Frame Path: ${this.framePathsMap.get(endPoint.detectionUuid)}`);

			return output;
		},
		getEntryThumbnail(entry){
			return entry.thumbNail;
		},
		recentMergeGalleryHoverAction(entry, index){

			// let detectionUuid = entry.firstDetectionUuid;
			let detectionUuid = entry.detectionUuid;

			//updates frame index to display on track map
			if(this.framePathsMap.get(detectionUuid) === undefined){

				this.currentImage = this.uniquePeopleFramePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.uniquePersonSubFramesMap.get(detectionUuid));
				// this.setCurrentDetectionUuid(detectionUuid);
			}
			else{

				this.currentImage = this.framePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.subFramesMap.get(detectionUuid));
				// this.setCurrentDetectionUuid(detectionUuid);
			}
		},
		frameHoverAction: function(index, detectionUuid){

			//updates frame index to display on track map
			this.frameHoverIndex = index
			if(this.framePathsMap.get(detectionUuid) === undefined){
				this.currentImage = this.uniquePeopleFramePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.uniquePersonSubFramesMap.get(detectionUuid));
				// this.setCurrentDetectionUuid(detectionUuid);
			}
			else{
				this.currentImage = this.framePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.subFramesMap.get(detectionUuid));
				// this.setCurrentDetectionUuid(detectionUuid);
			}
		},
		frameHoverAction2: function(index, detectionUuid){
			//updates frame index to display on track map
			this.frameHoverIndex2 = index

			if(this.uniquePeopleFramePathsMap.get(detectionUuid)===undefined){
				this.currentImage = this.framePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.subFramesMap.get(detectionUuid));
				// this.setCurrentDetectionUuid(detectionUuid);
			}
			else{
				this.currentImage = this.uniquePeopleFramePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.uniquePersonSubFramesMap.get(detectionUuid));
				// this.setCurrentDetectionUuid(detectionUuid);
			}
		},
		frameHoverAction3: function(index, detectionUuid){
			//updates frame index to display on track map
			this.frameHoverIndex3 = index
			if(this.framePathsMap.get(detectionUuid) === undefined){
				this.currentImage = this.uniquePeopleFramePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.uniquePersonSubFramesMap.get(detectionUuid));
			}
			else{
				this.currentImage = this.framePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.subFramesMap.get(detectionUuid));
			}
		},
		frameHoverAction4: function(index, detectionUuid){
			//updates frame index to display on track map
			this.frameHoverIndex4 = index
			if(this.uniquePeopleFramePathsMap.get(detectionUuid)===undefined){
				this.currentImage = this.framePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.subFramesMap.get(detectionUuid));
			}
			else{
				this.currentImage = this.uniquePeopleFramePathsMap.get(detectionUuid);
				this.setCurrentSubFrame(this.uniquePersonSubFramesMap.get(detectionUuid));
			}
		},
		setCurrentDetectionUuid: function(detectionUuid){
			this.currentDetectionUuid = detectionUuid;
		},
		getCurrentDetectionUuid: function(){
			if (this.currentDetectionUuid!= null){
				return this.currentDetectionUuid;
			}
		},
		setCurrentSubFrame: function(currentSubFrame){
			this.currentSubFrame = currentSubFrame;
		},
		//set current subframe map to display on fullframe preview
		getCurrentSubFrame: function(){
			if (this.currentSubFrame !== null){
				return this.currentSubFrame;
			}
		},
		setCurrentGlobalMerge: function(mergeUuid){
			this.currentSelectedMergeTrack = mergeUuid;
		},
		viewGlobalTrackSui: function(trackUuid){
			const url = window.location.href;
			const database = this.db
			const trackUrl = "http://" + url.split('/')[2] +'/db/'+ database + '/track/' + trackUuid;
			window.open(trackUrl, '_blank');
		},
		viewLocalTrackSui: function(localTrackUuid){
			const url = window.location.href;
			const database = this.db;
			const trackUrl = "http://" + url.split('/')[2] +'/db/'+ database + '/track/' + this.currentSelectedGlobalTrack + '/' + localTrackUuid;
			window.open(trackUrl, '_blank');
		},
		viewMergeTrackSui: function(trackUuid){
			const url = window.location.href
			const database = this.db;
			const trackUrl = "http://" + url.split('/')[2] +'/db/'+ database + '/track/' + trackUuid;
			window.open(trackUrl, '_blank');
		},
		// logic for selecting frames.
		frameClickedFinder: function(frameIndex,detectionUuid,event){


			this.currentSelectedGlobalTrack = this.availablePoints.filter(point => point['detectionUuid'] === detectionUuid)[0]['trackUuid']
			this.currentSelectedLocalTrack = this.availablePoints.filter(point => point['detectionUuid'] === detectionUuid)[0]['localTrackUuid']

			if (event.shiftKey === true && this.lastSelectedResultIndex !== -1 && this.lastSelectedResultIndex < frameIndex) {
				// console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
				for (let i = this.lastSelectedResultIndex + 1; i <= frameIndex; i++) {
					this.selectedFromResult.add(i);
					this.selectedCandidates.add(this.candidates[i]);
				}
				this.lastSelectedResultIndex = frameIndex;
			} else if (this.selectedFromResult.has(frameIndex)) {
				// console.log(`... remove ${frameIndex}`);
				this.selectedFromResult.delete(frameIndex);
				this.selectedCandidates.delete(detectionUuid);
			} else {
				// console.log(`... add ${frameIndex}`);
				this.selectedFromResult.add(frameIndex);
				this.selectedCandidates.add(detectionUuid);
				this.lastSelectedResultIndex = frameIndex;
			}
		},
		frameClicked: function(frameIndex, event) {
			if(event.which !== 3){
				// additional debug info for vue dev tools
				this.currentSelectedGlobalTrack = this.availablePoints[frameIndex].trackUuid;
				this.currentSelectedLocalTrack = this.availablePoints[frameIndex].localTrackUuid;
				// console.log("frame clicked",event)

				if (event.shiftKey === true && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex < frameIndex) {
					// console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
					for (let i = this.lastSelectedPointIndex + 1; i <= frameIndex; i++) {
						this.selectedPointIndexes.add(i);
					}
					this.lastSelectedPointIndex = frameIndex;
				}
				else if (event.shiftKey === true && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex > frameIndex) {
					// console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
					for (let i = frameIndex; i <= this.lastSelectedPointIndex; i++) {
						this.selectedPointIndexes.add(i);
					}
					this.lastSelectedPointIndex = frameIndex;
				} else if (this.selectedPointIndexes.has(frameIndex)) {
					// console.log(`... remove ${frameIndex}`);
					this.selectedPointIndexes.delete(frameIndex)
				} else {

					// console.log(`... add ${frameIndex}`);
					this.selectedPointIndexes.add(frameIndex);
					this.lastSelectedPointIndex = frameIndex;
				}
			}
		},
		mergeTrackClicked: function(frameIndex, event, mergeUuid) {
			// console.log(`frame ${frameIndex} clicked${event.shiftKey === true ? ' with shift key' : ''}`);
			this.setCurrentGlobalMerge(mergeUuid)
			if(this.selectedMergeIndexes.has(mergeUuid)){
				this.selectedMergeIndexes.delete(mergeUuid)
			}
			else{
				this.selectedMergeIndexes.add(mergeUuid);
			}
		},
		// Find matching start/end points that match to viewed frame
		getTrackIndexPair: function(_frameHoverIndex) {
			if (_frameHoverIndex < 0) {
				return;
			}
			const framePoint = this.startPoints[_frameHoverIndex];
			let indexPair = [];
			for (let i = 0; i < this.points.length; i++) {
				if (this.points[i].trackUuid === framePoint.trackUuid
					&& (framePoint.localTrackUuid === undefined || this.points[i].localTrackUuid === framePoint.localTrackUuid)) {
					indexPair.push(i);
				}
			}
			if ([1,2].includes(indexPair.length)) {
				if (indexPair.length === 1) {
					return [indexPair[0], indexPair[0]];
				} else {
					return indexPair;
				}
			} else {
				// console.error(`Expected to find one or two matching index values, but found ${indexPair.length}`);
				console.log(framePoint);
			}
		},
		localSetClipCss: function(coords, width, height) {
			return setClipCss(coords, width, height, this.imageCardSize.fixedCropWidth, this.hasPreCroppedFrames);
		},
		mergeTracks: function() {
			console.log(`Merging ${this.selectedPointIndexes.size} tracks at ${new Date(Date.now())}`);
			let reidTableName = this.currentLocalTrackList;
			if((this.finderToggle === false) && (this.selectedPointIndexes.size>0)){
				let hasMergeUuid = false;
				let newAvailablePoints = [];
				let mergeEntries = [];
				let mergeEntriesDetectionUuids = [];

				this.availablePoints.forEach((point, index) => {
					if (this.selectedPointIndexes.has(index)) {
						mergeEntries.push({
							detectionUuid: point.detectionUuid,
							localTrackUuid: point.localTrackUuid,
							trackUuid: point.trackUuid,
							pageNum: point.num,
							framePath:  point.framePath
						});
						mergeEntriesDetectionUuids.push(point.detectionUuid);
						this.pointsMerged.push(point);
					} else {
						newAvailablePoints.push(point);

					}
				});

				this.availablePoints = newAvailablePoints;

				const url = `/${this.db}/merge`;


				const body = {
					mergeEntries: mergeEntries,
					username: this.username.trim(),
					detectionUuids: mergeEntriesDetectionUuids.join(),
					taskUuid: this.taskUuid,
					pageNumber: this.currentBlock,
					reidTableName: reidTableName,
					user: this.username
				};
				dataAPI
					.post(url, body)
					.then(response => {
						let mergeUuid = response.data;
						console.log(mergeUuid);
						this.recentCreatedMergeUuid = mergeUuid;

						let detectionFrames = mergeEntries.map(localtrack => {
							return {
								framePath: this.framePathsMap.get(localtrack.detectionUuid)
							}
						})

						this.globalMergedTracks.push({
							mergeUuid: mergeUuid,
							thumbNail: this.framePathsMap.get(mergeEntries[0].detectionUuid),
							detectionUuid: mergeEntries[0].detectionUuid,
							framePath: mergeEntries[0].framePath,
							minBlockNum: this.currentBlock,
							detectionFrames: detectionFrames,
							subFrame: this.subFramesMap.get(mergeEntries[0].detectionUuid),
							taskUuid: this.taskUuid,
							minInsert: new Date()
						});
						console.log(`done at ${new Date(Date.now())}`)
					})
					.catch(e => {
						console.log(e);
						this.pushFailNotification("Merge event failed! Please check the logs");
					});
				this.clearSelected();
			}
		},
		undoMerge: function() {
			let confirm = window.confirm();
			if(confirm){
				const url = `${this.db}/undoMerge/`;
				const body = {
					entryToDelete: this.recentCreatedMergeUuid
				}
				dataAPI
					.post(url, body)
					.then(response => {
						console.log('deletion response:', response);
						console.log(`Sucessfully Deleted MergeUuid from ${this.db}_labels.trackMergeReal`)
					})
					.catch(error => {

					});
			}
		},

		mergeRecentTracks: function(mergeUuid) {

			console.log(mergeUuid);
			console.log(`Adding ${this.selectedPointIndexes.size} tracks to Merge Block with ID: ${mergeUuid}`);
			let newAvailablePoints = [];
			let mergeEntries = [];
			let mergeEntriesDetectionUuids = [];


			this.availablePoints.forEach((point, index) => {
				if (this.selectedPointIndexes.has(index)) {
					mergeEntries.push({
						detectionUuid: point.detectionUuid,
						framePath: point.framePath,
						localTrackUuid: point.localTrackUuid,
						trackUuid: point.trackUuid,
						pageNum: point.num
					});
					mergeEntriesDetectionUuids.push(point.detectionUuid);
					this.pointsMerged.push(point);
				} else {
					newAvailablePoints.push(point);
				}
			});


			console.log("merge entries: ",mergeEntries );

			function wait(ms){
				let start = new Date().getTime();
				let end = start;
				while(end < start + ms) {
					end = new Date().getTime();
				}
			}

			// const remainingPoints = this.points.filter(p=>p.isStart===1).filter(p=>!(this.availablePoints.includes(p))).filter(p=>!(this.pointsMerged.includes(p)));
			//     //maybe do some sorting here
			// const makeupdiff =  this.pageSize-newAvailablePoints.length;
			// const localTrackReplacements = remainingPoints.slice(0,Math.min(remainingPoints.length,makeupdiff));

			// localTrackReplacements.forEach(r=>{
			//     newAvailablePoints.push(r);
			// });

			this.availablePoints = newAvailablePoints;
			let reidTableName = '';
			if(this.taskUuid === ''){
				reidTableName = this.currentLocalTrackList
			}
			else{
				reidTableName = this.currentLocalTrackList;
			}
			const url = `/${this.db}/mergerecent/${mergeUuid}`;
			const body = {
				mergeEntries: mergeEntries,
				username: this.username.trim(),
				detectionUuids: mergeEntriesDetectionUuids.join(),
				taskUuid: this.taskUuid,
				pageNumber: this.currentBlock,
				reidTableName: this.currentLocalTrackList,
				user: this.username
			};
			dataAPI
				.post(url, body)
				.then(response=>{
					wait(1000)
					if(this.$refs.mergeEditor.mergeUuid === mergeUuid){
						console.log("TRUE")
						this.$refs.mergeEditor.refresh()
					}
				})
				.catch(e => {
					console.log(e);
					this.pushFailNotification("Merge event failed! Please check the logs");
				});
			this.clearSelected();
			this.finderToggle=false;
			this.clearResults();
			// this.retrieveReidLabellingProgress();
		},

		loadBlockNum: function(intervalNum){
			const currentPosition = (this.taskUuid !== "") ? this.allocatedBlocks.findIndex(block => parseInt(block) === parseInt(intervalNum)) : intervalNum;

			const nextPosition = (this.taskUuid !== "") ? this.allocatedBlocks[currentPosition] : parseInt(intervalNum);

			if(nextPosition === undefined){

				this.$noty.error(`Error: page not assigned to you `, {layout:'top',killer:false});

			}else{
				this.retrieveTrackEndPoints(nextPosition);
				this.currentBlock = nextPosition;
				this.selectedFilteredBlock = this.currentBlock;
				this.selectedBlock = this.currentBlock;
				this.dataLoaded = false;
				this.candidates = null;
				this.queryEntry = null
				this.comparisonDistances = null;
			}

		},
		loadNext: function(intervalNum){
			const currentPosition = (this.taskUuid !== "") ? this.allocatedBlocks.findIndex(block => parseInt(block) === parseInt(intervalNum)) : intervalNum;
			const nextPosition = (this.taskUuid !== "") ? this.allocatedBlocks[currentPosition+1] : parseInt(intervalNum) + 1;
			if(nextPosition === undefined){
				this.$noty.error(`Error: page not assigned to you `, {layout:'top',killer:false});
			}else{
				this.retrieveTrackEndPoints(nextPosition);
				this.currentBlock = nextPosition;
				this.selectedFilteredBlock = this.currentBlock;
				this.selectedBlock = this.currentBlock;
				this.dataLoaded = false;
				this.candidates = null;
				this.queryEntry = null
				this.comparisonDistances = null;
			}
		},
		loadPrevious: function(intervalNum){
			const currentPosition = (this.taskUuid !== "") ? this.allocatedBlocks.findIndex(block => parseInt(block) === parseInt(intervalNum)) : intervalNum;
			const nextPosition = (this.taskUuid !== "") ? this.allocatedBlocks[currentPosition-1] : parseInt(intervalNum) - 1;
			if(nextPosition === undefined){
				this.$noty.error(`Error: page not assigned to you `, {layout:'top',killer:false});
			}
			else{
				this.retrieveTrackEndPoints(nextPosition);
				this.currentBlock = nextPosition;
				this.selectedFilteredBlock = this.currentBlock;
				this.selectedBlock = this.currentBlock;
				this.dataLoaded = false;
				this.candidates = null;
				this.queryEntry = null
				this.comparisonDistances = null;
			}

		},
		async retrieveFramePaths(detections, cropped, datasetName, subFrames=false){
			
			try{
				const portalDetails = this.portalDetails??undefined;
				const { data } = await dataAPI.post(`${this.db}/frames/frameDetails`, { detections, cropped, datasetName, withSubframes: subFrames, portalDetails });
				return data;
			}
			catch(error){
				console.error(error);
				this.$noty.error("Failed to retrieve image urls");
			}
		},
		retrieveFullFrames: async function() {
			const detections = this.points.map(point => {
				return {
					detectionUuid: point.bestDetectionUuid || point.detectionUuid
				}
			});
			
			const framePaths = await this.retrieveFramePaths(detections, this.hasPreCroppedFrames, this.currentLocalTrackList);
			this.framePathsMap = this.createFramePathMap(framePaths);
			const sizeMap = await createImageSizeMap(this.framePathsMap);
			this.sizeMap = {...this.sizeMap, ...sizeMap};
		},
		async retrieveGloballyMergedThumbnails(globalMergedTracks) {
			console.log(`Retrieving MergedThumbnails frame paths for detections...`, globalMergedTracks);

			const detections = globalMergedTracks.map(globalMergedTracks => {return {detectionUuid:  globalMergedTracks.detectionUuid, framePath: globalMergedTracks.framePath}});

			const framePaths = await this.retrieveFramePaths(detections, this.hasPreCroppedFrames, this.currentLocalTrackList);
			this.uniquePeopleFramePathsMap = this.createFramePathMap(framePaths);

			const sizeMap = await createImageSizeMap(this.uniquePeopleFramePathsMap);
			this.sizeMap = {...this.sizeMap, ...sizeMap};
			this.retrieveUniquePersonSubFrames(globalMergedTracks);
		},
		retrieveDetectionSubFrames: function() {
			console.log('Retrieving subFrames for detections...');

			const subFramesObjList = this.points.map(point => {
				return {
					detectionUuid: point.detectionUuid,
					minimumX: point.minimumX,
					minimumY: point.minimumY,
					maximumX: point.maximumX,
					maximumY: point.maximumY,
					label: "person",
					textureLabel: "rgb"
				}
			})
			console.log("subFramesObjList", subFramesObjList)

			this.subFramesMap = this.createSubFramesMap(subFramesObjList);

		},
		retrieveUniquePersonSubFrames: function(globalMergedTracks) {
			console.log('Retrieving UniquePersonSubFrames for detections...');

			const detections = globalMergedTracks.map(globalMergedTracks => globalMergedTracks.detectionUuid);
			if(detections.length){
				const localTrackList = this.currentLocalTrackList;
				dataAPI
					.post(`/${this.db}/detections/subframes/`, { detections, reidInput: "reidInput_"+localTrackList})
					.then(response => {
						console.log(`Retrieving unique person subFrames for detections... ${response.data.length} subFrames returned`);

						this.uniquePersonSubFramesMap = this.createSubFramesMap(response.data);
					})
					.catch(e => {

						console.log(e);
						this.uniquePersonSubFramesMap = null;
					});
			}

		},
		/**
			 * retrieveUnlabelledTracks
			 * Allows admins to view tracks as if they were unlabelled, helpful for debugging and seeing system performance post labelling
			 * @param {Int} pageNum  page number...
			 */
		retrieveUnlabelledTracks: function(pageNum){
			dataAPI
				.post(`/${this.db}/datasets/getTracksFromPage`,
					{
						pageNumber: pageNum,
						dataset: this.currentLocalTrackList,
					})
				.then(response => {
					console.log(`Retrieving start/end points for local tracks... ${response.data.length} points returned`);
					this.points = response.data;

					if(this.points.length === 0){
						this.availablePoints = []
						this.points = [];
						this.$noty.error('Page fully labelled!', {layout : 'top', killer: true})
					}
				})
				.catch(e => {
					console.log(e);
					this.points = [];
				});
		},
		retrieveTrackEndPoints: function(intervalNum) {

			console.log(`Page ${intervalNum} Retrieving start/end points for local tracks...`);

			const localTrackList = this.currentLocalTrackList

			dataAPI.get(`/${this.db}/detections/localTrackStartEnd`,
					{
						params:
							{
								intervalNum: intervalNum,
								localTrackList: localTrackList,
								isReduced: this.isReduced
							}
					})
					.then(response => {
						console.log(`Retrieving start/end points for local tracks... ${response.data.length} points returned`);
						this.points = response.data;
						if(this.points.length === 0){
							this.availablePoints = []
							this.points = [];

							this.$noty.error('Page fully labelled!', {layout : 'top', killer: true})
						}
					})
					.catch(e => {
						console.log(e);
						this.points = [];
					});
		},
		retrieveGlobalMergedTracks: async function(currentLocalTrackList) {
			console.log('Retrieving global merged tracks from database...');

			const { db, isReduced } = this;
			const url = `/${db}/detections/representativeFrame`;

			this.globalMergedTracks = [];

			try{
				this.$root.$emit('setIsLoading', true);
				const { data } = await dataAPI.get(url, { params : {localTrackList: currentLocalTrackList, isReduced } })
				console.log(`Retrieving global merged tracks... ${data.length} merged tracks returned`);
				this.globalMergedTracks = data.sort((a, b) => (parseInt(a.minBlockNum) > parseInt(b.minBlockNum)) ? 1 : -1);
				// only retrieve frames for track points inside our current view

				this.retrieveGloballyMergedThumbnails(data.filter(r=> parseInt(r.minBlockNum) === this.filteredGlobalMergeBlockNum));
			}
			catch(error){
				console.log(error);
			}
			finally {
				this.$root.$emit('setIsLoading', false)
			}
		},
		retrieveActiveReidDatasets: function(){
			console.log("Retrieving all local track lists from database...");
			dataAPI
				.get(`/datasets/getActiveDatasets`)
				.then(response => {
					console.log(response);
					console.log(`Retrieving all local track lists from database ... ${response.data.length} lists returned`);

					this.localTrackLists = response.data;

				})
				.catch(e =>{
					console.log(e);
				})
		},
		setTrackStyle: function(index) {

			let styleBuilder = "";
			const _sp = this.availablePoints;
			const length = _sp.length;
			styleBuilder += 'margin-bottom: 10px;';
			// First local within global
			if (index === 0 || _sp[index-1].trackUuid !== _sp[index].trackUuid) {
				styleBuilder += 'border-top-left-radius: 6px;';
				styleBuilder += 'border-bottom-left-radius: 6px;';
				styleBuilder += 'border-left-width: 3px;';
				styleBuilder += 'margin-left: 2px;';
			} else {

				styleBuilder += 'border-left-width: 0px;';
			}
			// Last local within global
			if (index === length - 1 || _sp[index].trackUuid !== _sp[index+1].trackUuid) {
				styleBuilder += 'border-top-right-radius: 6px;';
				styleBuilder += 'border-bottom-right-radius: 6px;';
				styleBuilder += 'border-right-width: 3px;';
				styleBuilder += 'margin-right: 20px;';
			} else {
				styleBuilder += 'border-right-width: 0px;';
			}

			return styleBuilder;
		},
		toggleImageCardSize: function() {
			this.size = this.size === 'xs' ? 'xl' : 'xs';
		},
		async showFullFrame(){
			const { fullFramePath, sizeMap, subFrame } = await this.retrieveFullFrameDetails(this.currentDetectionUuid);

			this.currentFullFrameImage = fullFramePath;
			this.fullFramePathSizeMap = sizeMap;

			this.currentFullFrameSubFrame = {
				...subFrame
			}

			this.$root.$emit('bv::show::modal', 'fullframe-modal');
		},
		async retrieveFullFrameDetails(detectionUuid){
			const framePathMap = new Map();
			const frameWithSubframes = await this.retrieveFramePaths([ {detectionUuid} ], false, this.currentLocalTrackList, true);

			const [{ minimumY: top, maximumX: right, maximumY: bottom, minimumX: left }] = frameWithSubframes;
		
			frameWithSubframes.forEach(detection => framePathMap.set(detection.detectionUuid, detection.frame));

			const fullFramePath = framePathMap.get(detectionUuid);

			const sizeMap = await createImageSizeMap(framePathMap, true);
	
			return {
				fullFramePath, 
				sizeMap,
				subFrame: {
					top, right, bottom, left
				}
			}
		}
	}
}

</script>

<style scoped>

.goToSui{
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 80px;

}
/* :not(.nohover):hover {
	display:none  !important;
} */
.v-sidebar-menu {

	height:50vh;
	margin-top:56px;
	position: fixed;
	z-index: 1000;

}

.button-row{

	display:flex;
	flex-direction: row;

	width: fit-content;
}

.button-row button{

	margin-left:1em;
}

.button-row select{

	margin-left:1em;
	margin-right:1em;
}
.goToSui .primary{

	width: fit-content;
	font-size: 0.75em;
	padding-left: 10px;
}

.ff-canvas_fullframe_preview{
	position: fixed;
	top: 8px;
	right: 8px;
	font-size: 18px;
	z-index: 10;
}

.map-container{
	position: fixed;
	top: 8px;
	right: 8px;
	font-size: 18px;
	/* width: 200px; */
	z-index: 10;
}

.button-controls {
	margin-bottom: 10px;
}

.button-controls button:not(:first-child) {
	margin-left: 10px;
} 

</style>
