
<div class="row" style="display:flex; margin-left:0.001rem; flex-direction: row; height: 100%; width:100%;background-color:transparent;">
	
	<div class="card mb-0" v-if="settingsToggled">
		<h5 class="card-header">Settings</h5>
		<div class="card-body">
			<div class="card mb-1">
				<h5 @click="toggleCameraSelect=!toggleCameraSelect" class="card-header">Filter By Camera</h5>
				<select v-if="toggleCameraSelect" class="form-control" v-model="selectedCamera">
					<option disabled value="">Filter by camera</option>
					<option v-for="camera in cameraSources" v-bind:key="camera">
						{{camera}}
					</option>

				</select>
			</div>
			<div class="card mb-1">
				<h5 class="card-header">Filter By Distance</h5>
			</div>
		</div>
	</div>
	<div :style="`display: flex; flex-direction: row; width: ${width};height:100%;background-color:transparent`">
		<div class="card mb-0" :style="setCardStyle()" style="background-color:transparent">
			<div class="row" style="display:flex; margin-left:0.01rem;">
				<vs-button
					icon
					style="outline: none;"
					color="success"
					v-if="pageNumber>0 && (totalPages>1)"
					@click="pageNumber-=1;"
				>
					<i class="fas fa-arrow-left"></i>
				</vs-button>
				<vs-button
					icon
					style="outline: none;"
					color="success"
					v-if="(pageNumber+1) < totalPages"
					@click="pageNumber+=1;"
				>
					<i class="fas fa-arrow-right"></i>
				</vs-button>
				<vs-button
					icon
					style="outline: none;"

					@click="merge()"
					v-if="selectedLocalTracks.size>0 && lastSelectedPointIndex.split('_')[1]!=='stagedPoints' && mergeable"
				>
					<h5 style="margin-bottom:1px;" >Add {{selectedLocalTracks.size}} to merge</h5>
				</vs-button>
			</div>
			<div class="card-body" style="width: fit-content;background-color:transparent">
				<div class="card mb-1">
					<div class="local-track-list">
						<div v-for="(point,index) in pointsMutable.filter(r=>r.pageNumber===this.pageNumber)" v-bind:key="point[pointType]"  class="local-track">
							<div class="mask" :class="setTrackClass(index+'_pointsMutablePage', frameHoverIndex, point[pointType])">

								<div class="card border-secondary">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										:style="imageCardSize.css"
										@mousedown="imageSelected(point);frameClicked(index+'_pointsMutablePage', $event); (frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null; lastSelectedPointIndex=index+'_pointsMutablePage';"
										@mouseover="frameHoverAction(index+'_pointsMutablePage', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null"
									>
										<div>
											<img
												v-if="sizeMap[detection.detectionUuid]!==undefined"
												:src="framePathsMap.get(detection.detectionUuid)"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid), sizeMap[detection.detectionUuid].width, sizeMap[detection.detectionUuid].height)"
												draggable="false"
												ondragstart="return false;"
											>
										</div>
									</div>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="false" class="card mb-0" :style="setCardStyle()">
			<div class="row" style="display:flex; margin-left:0.01rem; ">
				<h5 class="card-header">Local Tracks Selected</h5>
				<vs-button
					icon
					style="outline: none;"
					@click="removeSelected()"
					v-if="selectedLocalTracks.size>0"
				>
					<h5 v-if="selectedLocalTracks.size>0" style="margin-bottom:1px;" >Remove {{selectedLocalTracks.size}} local track</h5>
				</vs-button>
				<vs-button
					icon
					style="outline: none;"
					@click="createVideo()"
					v-if="stagedPoints.length>0"
				>
					<h5 style="margin-bottom:1px;">Create Video</h5>
				</vs-button>
			</div>
			<div  class="card-body">
				<div class="card mb-1">
					<div class="local-track-list">
						<div v-for="(point,index) in stagedPointsSorted()" :key="index"  class="local-track">
							<div class="mask" :class="setTrackClass(index+'_stagedPoints', frameHoverIndex, point[pointType])">
								<div class="card border-secondary mb-1">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										@mousedown="frameClicked(index+'_stagedPoints', $event); (frameHoverIndex === index+'_stagedPoints') ? frameHoverIndex = -1 : null; lastSelectedPointIndex=index+'_stagedPoints';"
										@mouseover="frameHoverAction(index+'_stagedPoints', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_stagedPoints') ? frameHoverIndex = -1 : null"
									>
										<div>
											<img
												:src="preloadedImagesMap.get(detection.detectionUuid).src"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid))"
												draggable="false"
												ondragstart="return false;"
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
