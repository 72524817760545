<template>


<v-stage class="image-container" ref="container" :config="forcedSize" @wheel="zoom" >
	
	<v-layer ref="layer" draggable="true" v-if="image" :config="{ scale: { x: stageScale, y: stageScale } }">
		
		<v-image
			:config="{
				image: image,
				height: stageHeight,
				width: stageWidth,
			}"
		/> 
		
		<v-rect
			:config="{
				stroke: 'red',
				strokeWidth: 2,
				x: activeSubframeBounds.left,
				y: activeSubframeBounds.top,
				width: activeSubframeBounds.width,
				height: activeSubframeBounds.height,
			}"
		/>
	</v-layer>
	
</v-stage> 
</template>

<script>
import { promiseLoadImage, scaleSubframeBoundsToDisplay } from '../../utils/images.js';

export default {
	name: 'TrackPointDisplay',

	props: {
		framePath: { type: String, default: '' },
		subFrame: {
			type: Object,
			default: () => ({}),
		},
		size: {
			type: Object,
			default: () => ({}),
		},
		imageScale: {
			type: Number,
			default: 3.5,
		},
		subFrameStyle: {
			type: String,
			default: 'bounding_box',
		},
		preloadedImage: {
			type: HTMLImageElement,
			default: null,
		},
	},

	data: () => ({
		image: null,
		resizeObserver: null,
		forcedSize: {},
		zoomRect: null,
		minScale: 1,
		maxScale: 10,
	}),

	computed: {
		activeSubframeBounds() {
			/* assumes image is display-scaled to stageSize
			 *  use first image of current track as display
			 *  TODO allow user to cycle through different angles of same track
			 */

			const { left, top, bottom, right } = this.subFrame;

			const { height, width } = this.size;

			return scaleSubframeBoundsToDisplay(
				{ left, right, top, bottom },
				width,
				height,
				this.stageWidth,
				this.stageHeight
			);
		},

		stageHeight() {
			return this.size.height / this.imageScale;
		},

		stageWidth() {
			return this.size.width / this.imageScale;
		},

		isVertical() {
			return this.size.width < this.size.height;
		},

		stageScale() {
			return Math.min(
				this.forcedSize.height / this.stageHeight,
				this.forcedSize.width / this.stageWidth
			);
		},

		aspectRatio() {
			return this.size.width / this.size.height;
		},
	},

	watch: {
		framePath: 'loadImage',
	},

	methods: {
		async loadImage() {
			if (this.preloadedImage) {
				this.image = this.preloadedImage;
			} else if (this.framePath) {
				this.image = (await promiseLoadImage(this.framePath)) || null;
			}
		},

		fitIntoParent() {
			let { clientWidth: parentWidth, clientHeight: parentHeight } = this.$el.parentElement;
		
			const style = getComputedStyle(this.$el.parentElement);
			parentWidth -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
			parentHeight -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);

			if (this.isVertical) {
				this.forcedSize = {
					width: parentHeight * this.aspectRatio,
					height: parentHeight,
				};
			} else {
				this.forcedSize = {
					width: parentWidth,
					height: parentWidth / this.aspectRatio,
				};
			}
		},
		zoom(e) {
			const { minScale, maxScale } = this;

			const scaleBy = 1.1;
			e.evt.preventDefault();
			const stage = this.$refs.container.getStage();

			const oldScale = stage.scaleX();

			const pointer = stage.getPointerPosition();
			const mousePointTo = {
				x: (pointer.x - stage.x()) / oldScale,
				y: (pointer.y - stage.y()) / oldScale,
			};
			const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
			if((newScale >= minScale) && (maxScale >= newScale)){

			
				stage.scale({ x: newScale, y: newScale });
				const newPos = {
					x: pointer.x - mousePointTo.x * newScale,
					y: pointer.y - mousePointTo.y * newScale,
				};

				stage.position(newPos);
			}
		},
	},

	mounted() {
		this.loadImage();
		this.resizeObserver = new ResizeObserver(this.fitIntoParent);
		this.resizeObserver.observe(this.$el.parentElement);
		this.fitIntoParent();
	},

	beforeDestroy() {
		this.resizeObserver.disconnect();
	},
};
</script>
