<template>
<div class="row" style="display:flex; margin-left:0.001rem; flex-direction: row; height: 100%; width:100%;background-color:transparent;">
	
	<div class="card mb-0" v-if="settingsToggled">
		<h5 class="card-header">Settings</h5>
		<div class="card-body">
			<div class="card mb-1">
				<h5 @click="toggleCameraSelect=!toggleCameraSelect" class="card-header">Filter By Camera</h5>
				<select v-if="toggleCameraSelect" class="form-control" v-model="selectedCamera">
					<option disabled value="">Filter by camera</option>
					<option v-for="camera in cameraSources" v-bind:key="camera">
						{{camera}}
					</option>

				</select>
			</div>
			<div class="card mb-1">
				<h5 class="card-header">Filter By Distance</h5>
			</div>
		</div>
	</div>
	<div :style="`display: flex; flex-direction: row; width: ${width};height:100%;background-color:transparent`">
		<div class="card mb-0" :style="setCardStyle()" style="background-color:transparent">
			<div class="row" style="display:flex; margin-left:0.01rem;">
				<vs-button
					icon
					style="outline: none;"
					color="success"
					v-if="pageNumber>0 && (totalPages>1)"
					@click="pageNumber-=1;"
				>
					<i class="fas fa-arrow-left"></i>
				</vs-button>
				<vs-button
					icon
					style="outline: none;"
					color="success"
					v-if="(pageNumber+1) < totalPages"
					@click="pageNumber+=1;"
				>
					<i class="fas fa-arrow-right"></i>
				</vs-button>
				<vs-button
					icon
					style="outline: none;"

					@click="merge()"
					v-if="selectedLocalTracks.size>0 && lastSelectedPointIndex.split('_')[1]!=='stagedPoints' && mergeable"
				>
					<h5 style="margin-bottom:1px;" >Add {{selectedLocalTracks.size}} to merge</h5>
				</vs-button>
			</div>
			<div class="card-body" style="width: fit-content;background-color:transparent">
				<div class="card mb-1">
					<div class="local-track-list">
						<div v-for="(point,index) in pointsMutable.filter(r=>r.pageNumber===this.pageNumber)" v-bind:key="point[pointType]"  class="local-track">
							<div class="mask" :class="setTrackClass(index+'_pointsMutablePage', frameHoverIndex, point[pointType])">

								<div class="card border-secondary">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										:style="imageCardSize.css"
										@mousedown="imageSelected(point);frameClicked(index+'_pointsMutablePage', $event); (frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null; lastSelectedPointIndex=index+'_pointsMutablePage';"
										@mouseover="frameHoverAction(index+'_pointsMutablePage', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null"
									>
										<div>
											<img
												v-if="sizeMap[detection.detectionUuid]!==undefined"
												:src="framePathsMap.get(detection.detectionUuid)"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid), sizeMap[detection.detectionUuid].width, sizeMap[detection.detectionUuid].height)"
												draggable="false"
												ondragstart="return false;"
											>
										</div>
									</div>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="false" class="card mb-0" :style="setCardStyle()">
			<div class="row" style="display:flex; margin-left:0.01rem; ">
				<h5 class="card-header">Local Tracks Selected</h5>
				<vs-button
					icon
					style="outline: none;"
					@click="removeSelected()"
					v-if="selectedLocalTracks.size>0"
				>
					<h5 v-if="selectedLocalTracks.size>0" style="margin-bottom:1px;" >Remove {{selectedLocalTracks.size}} local track</h5>
				</vs-button>
				<vs-button
					icon
					style="outline: none;"
					@click="createVideo()"
					v-if="stagedPoints.length>0"
				>
					<h5 style="margin-bottom:1px;">Create Video</h5>
				</vs-button>
			</div>
			<div  class="card-body">
				<div class="card mb-1">
					<div class="local-track-list">
						<div v-for="(point,index) in stagedPointsSorted()" :key="index"  class="local-track">
							<div class="mask" :class="setTrackClass(index+'_stagedPoints', frameHoverIndex, point[pointType])">
								<div class="card border-secondary mb-1">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										@mousedown="frameClicked(index+'_stagedPoints', $event); (frameHoverIndex === index+'_stagedPoints') ? frameHoverIndex = -1 : null; lastSelectedPointIndex=index+'_stagedPoints';"
										@mouseover="frameHoverAction(index+'_stagedPoints', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_stagedPoints') ? frameHoverIndex = -1 : null"
									>
										<div>
											<img
												:src="preloadedImagesMap.get(detection.detectionUuid).src"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid))"
												draggable="false"
												ondragstart="return false;"
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import VueContext from 'vue-context';
import { dataAPI, kitchenSync, benchmarkKitchenSync} from "../../http-common";
import {setClipCss, getImageDimensions, createImageSizeMap} from '../../utils/images.js';
import { BButton} from 'bootstrap-vue'  ;

import Vue from 'vue';
import jQuery from 'jquery'
Vue.prototype.jQuery = jQuery
Vue.component('vue-context', VueContext);
Vue.component('b-button', BButton)
export default {
	name: 'DetectionGallery',
	components: {
		VueContext,
		BButton
	},
	props: {

		points: {type: Array, default:[]},
		pointsToExclude: {type: Array, default:[]},
		from: {type: String, default: ''},
		pageSize: {type: Number, default: 25},
		to: {type: String, default: ''},
		mergeUuid: {type: String, default: ''},
		settingsEnabled: false,
		dataset: {type: String, default: ''},
		mergeable: {type: Boolean, default: false},
		sortPoints: {type: Boolean, default: true},
		imagesLoaded: {type: Boolean, default: false},
		pointType: {type: String, default: 'localTrackUuid'},
		width: {type: String, default: 'fit-content'},
		size: {type: String, default: 'xs'},
		hasCroppedFrames: {type: Boolean, default: false}
	},
	data: function() {
		return {
			subFramesMap: new Map(),
			framePathsMap: new Map(),
			shiftKey: null,
			clickEngaged: false,
			frameHoverIndex: null,
			lastSelectedPointIndex: -1,
			selectedPointIndexes: new Set(),
			selectedCamera: 'all',
			pageNumber: 0,
			settingsToggled: false,
			pipelineProgress: {'trackPoints': false, 'frames': false},
			preloadedImagesMap: new Map(),
			selectedLocalTracks: new Set(),
			toggleCameraSelect: false,
			lastVideoCreated: '',
			stagedPoints: [],
			pointsMutablePage: [],
			pointsMutable: [],
			currentDetection: undefined,
			sizeMap: {},
		}
	},
	computed: {
		emptyPoints: function(){
			return {};
		},
		cameraSources: function(){
			const unique = (value, index, self) => {
				return self.indexOf(value) === index;
			}
			let uniqueCameras = this.points.map(r=>r.sourceNodeUuid).filter(unique).sort((a, b) => {
				return parseInt(a.split('-')[1]) - parseInt(b.split('-')[1]);
			});

			uniqueCameras.push('all');

			return uniqueCameras;
		},
		pointsToShow: function(){
			return this.pointsMutablePage
				.slice((this.pageNumber*this.pageSize),(this.pageNumber*this.pageSize)+this.pageSize)
				.filter(r=> !((this.pointsToExclude.includes(r.detectionUuid)) || (this.pointsToExclude.includes(r.localTrackUuid))))
		},
		totalPages: function(){
			return Math.ceil(this.points.length/this.pageSize)
		},
		selectedLocalTracksArray(){
			return Array.from(this.selectedLocalTracks);
		},
		imageCardSize(){
			
			if(this.size === 'xl'){
				const width = 120;
				return {
					fixedCropWidth: width,
					css: `width: ${width}px; height: ${width*1.7}px;`
				};
			}
			else{
				const width = 75;
				return {
					fixedCropWidth: 75,
					css: `width: ${width}px;`
				};
			}
		},
	},
	watch: {

		pageNumber: async function(newval){

			this.updatePipeline(this.pointsMutable.filter(r=>r.pageNumber  === newval));
		},
		selectedCamera: function(newval){
			if(newval === 'all'){
				this.pointsToShow=this.points
					.slice((newval*this.pageSize),(newval*this.pageSize)+this.pageSize)
					.filter(r=> !((this.pointsToExclude.includes(r.detectionUuid)) || (this.pointsToExclude.includes(r.localTrackUuid))))
			}
			if(newval !== 'all'){

				this.pointsToShow = this.points
					.filter(r=>r.sourceNodeUuid === this.selectedCamera);//.slice((this.pageNumber*this.pageSize),(this.pageNumber*this.pageSize)+this.pageSize)

			}
		},
		points: function(newVal){
			this.pointsMutable = newVal;
			let page = 0;
			this.pointsMutable.forEach((point, index) => {
				if (((index+1) % this.pageSize) === 0){
					point['pageNumber'] = page++;
				}
				else{
					point['pageNumber'] = page;
				}
			})
			this.pointsMutablePage = this.pointsMutable.slice((this.pageNumber*this.pageSize),(this.pageNumber*this.pageSize)+this.pageSize);
			this.updatePipeline(this.pointsMutable.filter(r=>r.pageNumber  === this.pageNumber));
		},
		currentDetection: function(newVal){
			this.$emit('setHighLightedDetection', newVal);
		},
		selectedLocalTracksArray: function(newVal){
			console.log('watcher is working')
			this.$emit('updateSelectedLocalTracks', newVal);
		}

	},
	mounted: function() {
		this.pointsMutable = (this.sortPoints) ? this.points.sort((a, b) => a['timestamp'] - b['timestamp']) : this.points;
		let page = 0;
		this.pointsMutable.forEach((point, index) => {
			if (((index+1) % this.pageSize) === 0){
				point['pageNumber'] = page++;
			}
			else{
				point['pageNumber'] = page;
			}
		})
		this.updatePipeline(this.pointsMutable.filter(r=>r.pageNumber === this.pageNumber));
	},
	methods: {
		async updatePipeline(points){

			this.$root.$emit('setIsLoading', true);


			if(this.imagesLoaded){
				
				this.framePathsMap = points.reduce((frameMap, r)=>{
					frameMap.set(r.detectionUuid, r.framePath)
					return frameMap;
				},new Map())


				this.subFramesMap = points.reduce((subFrameMap, r)=>{
					subFrameMap.set(r.detectionUuid, {
						top: r.minimumY,
						right: r.maximumX,
						bottom: r.maximumY,
						left: r.minimumX
					})

					return subFrameMap;
				
				},new Map())

				points.forEach(async point => {
					let image = new Image();
					let path = this.framePathsMap.get(point.detectionUuid);
					image.src = path;
					this.preloadedImagesMap.set(point.detectionUuid, image);
				})
			}
			else{

				let pointsToRequest=[];

				pointsToRequest=points.filter(r=>!this.framePathsMap.get(r.detectionUuid));

				// only request point not in cache:
				const supportingData = await Promise.all([this.retrieveSubFrames(pointsToRequest), this.retrieveFrames(pointsToRequest)]);

				if(this.subFramesMap !== null){
					console.log('debug', this.subFramesMap)
					this.subFramesMap = new Map([...this.subFramesMap, ...this.createSubFramesMap(supportingData[0].data)])
				}
				else{
					this.subFramesMap = this.createSubFramesMap(supportingData[0].data);
				}

				if(this.framePathsMap.size > 0){
					this.framePathsMap = new Map([...this.framePathsMap, ...this.createFramePathMap(supportingData[1].data)])
				}
				else{
					console.log('frame path should be here')
					this.framePathsMap = this.createFramePathMap(supportingData[1].data);
					console.log(this.framePathsMap)
				}

				supportingData[0].data.forEach(async point => {
					let image = new Image();
					let path = this.framePathsMap.get(point.detectionUuid);
					image.src = path;
					this.preloadedImagesMap.set(point.detectionUuid, image);
				})
			}

			this.sizeMap = {...this.sizeMap, ...(await createImageSizeMap(this.framePathsMap))};	

			this.$root.$emit('setIsLoading', false);
			
		},
		stagedPointsSorted: function(){
			return this.stagedPoints;
		},
		retrieveSubFrames(points){
			if(points.length>0){
				const detections = points.map(r=>r.detectionUuid).map(uuid => `'${uuid}'`).toString();
				return dataAPI
					.post(`/detections/subFrames`, { detections: detections, from: this.from, to: this.to, dataset: (this.dataset==='') ? '' : this.dataset})
					.catch(e => {
						console.log(e);
					})
			}
			else{
				return {data: []}
			}
		},
		retrieveFrames(points){

			let url = (this.dataset === '') ? `frame/get/track_points/rgb/jpg/hfm1` : `frame/get/track_points/rgb/jpg/${this.dataset}`;

			if(points.length>0){
				return dataAPI.post(url, {
					from: this.from,
					to: this.to,
					track_points: points.map(r=>r.detectionUuid),
					isBenchMark: (this.dataset === '') ? false : true
				});
			}
			else{
				return {data:[]}
			}

		},
		createFramePathMap: function(rawData) {
			let _framePathsMap = new Map();
			rawData.forEach(path => _framePathsMap.set(path.uuid, path.frame));

			return _framePathsMap;
		},
		createSubFramesMap: function(rawData) {
			let _subFramesMap = new Map();
			rawData
				.filter(subFrame => subFrame.label === 'person' && subFrame.textureLabel === 'rgb')
				.forEach(subFrame => _subFramesMap.set(subFrame.detectionUuid, {
					top: JSON.parse(subFrame.subFrames)[0].min_y,
					right: JSON.parse(subFrame.subFrames)[0].max_x,
					bottom: JSON.parse(subFrame.subFrames)[0].max_y,
					left: JSON.parse(subFrame.subFrames)[0].min_x
				}));
			return _subFramesMap;
		},

		localSetClipCss: function(coords, width, height) {
			return setClipCss(coords, width, height, this.imageCardSize.fixedCropWidth, this.hasCroppedFrames);
		},
		frameHoverAction: function(index, detectionUuid){

			//updates frame index to display on track map
			this.frameHoverIndex = index;

		},
		frameClicked: function(frameIndex, event) {
			// this.currentSelectedGlobalTrack = this.availablePoints[frameIndex].trackUuid;
			let frameIndexValue=parseInt(frameIndex.split('_')[0])
			// lastSelectedPointIndex = parseInt(this.lastSelectedPointIndex.split('_')[0])
			// if (event.shiftKey === true && lastSelectedPointIndex !== -1 && lastSelectedPointIndex < frameIndex) {
			//     // console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
			//     for (let i = _this.lastSelectedPointIndex + 1; i <= frameIndex; i++) {
			//         _this.selectedPointIndexes.add(i);
			//         _this.selectedLocalTracks.add(_this.pointsToShow[i].localTrackUuid)
			//     }
			//     lastSelectedPointIndex = frameIndex;
			// }
			// else if (event.shiftKey === true && lastSelectedPointIndex !== -1 && lastSelectedPointIndex > frameIndex) {
			//     // console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
			//     for (let i = frameIndex; i <= lastSelectedPointIndex; i++) {
			//         _this.selectedPointIndexes.add(i);
			//         _this.selectedLocalTracks.add(_this.pointsToShow[i].localTrackUuid)
			//     }
			//     lastSelectedPointIndex = frameIndex;
			// } else if (_this.selectedPointIndexes.has(frameIndex)) {
			//     console.log(`... remove ${frameIndex}`);
			//     lastSelectedPointIndex = -1;
			//     _this.selectedPointIndexes.delete(frameIndex)
			//     _this.selectedLocalTracks.delete(_this.pointsToShow[frameIndex].localTrackUuid)

			//     console.log(this.selectedPointIndexes);

			if (this.selectedPointIndexes.has(frameIndex)) {
				console.log(`... remove ${frameIndexValue}`);
				this.lastSelectedPointIndex = -1;
				this.selectedPointIndexes.delete(frameIndex)

				let galleryToChange = (frameIndex.split('_')[1] === 'stagedPoints') ? this.stagedPoints: this.pointsMutable;

				this.selectedLocalTracks.delete(galleryToChange.filter(r=>r.pageNumber === this.pageNumber)[frameIndexValue][this.pointType])
				this.$emit('updateSelectedLocalTracks', this.selectedLocalTracks);

			} else {

				// console.log(`... add ${frameIndex}`);
				this.selectedPointIndexes.add(frameIndex);
				let galleryToChange = (frameIndex.split('_')[1] === 'stagedPoints') ? this.stagedPoints: this.pointsMutable.filter(r=>r.pageNumber === this.pageNumber);
				this.selectedLocalTracks.add(galleryToChange[frameIndexValue][this.pointType])
				this.$emit('updateSelectedLocalTracks', this.selectedLocalTracks);
				this.lastSelectedPointIndex = -1;
				// _this.lastSelectedPointIndex = frameIndex;
			}
		},
		selectedByDrag(frameIndex, event){
			if((this.frameHoverIndex === -1)){
				// do nothing
			}
			else{
				if (event.which === 1 && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex < frameIndex) {
					// console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
					for (let i = this.lastSelectedPointIndex + 1; i <= frameIndex; i++) {
						this.selectedPointIndexes.add(i);
					}
					this.lastSelectedPointIndex = frameIndex;
				}
				else if (event.which === 1 && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex > frameIndex) {
					// console.log(`... add range from ${this.lastSelectedPointIndex + 1} to ${frameIndex}`);
					for (let i = frameIndex; i <= this.lastSelectedPointIndex; i++) {
						this.selectedPointIndexes.add(i);
					}
					this.lastSelectedPointIndex = frameIndex;
				}
			}
		},
		setTrackClass(index, frameHoverIndex, localTrackUuid){

			let _this = this;

			// let shiftSelectRangeStart = Math.min(this.lastSelectedPointIndex, frameHoverIndex);
			// let shiftSelectRangeEnd = Math.max(this.lastSelectedPointIndex, frameHoverIndex);
			// let shiftRangeLength = Math.abs(this.lastSelectedPointIndex-frameHoverIndex);

			// let shiftIndexes = (this.lastSelectedPointIndex < frameHoverIndex) ? [...Array(shiftRangeLength).keys()].map(r=>r + shiftSelectRangeStart+1):[...Array(shiftRangeLength).keys()].map(r=>r + shiftSelectRangeStart)

			// console.log("shiftIndexes", shiftIndexes)
			// console.log("shiftIndexes _ shiftSelectRangeStart", shiftSelectRangeStart)
			// console.log("shiftIndexes _ shiftSelectRangeEnd", shiftSelectRangeEnd)
			// console.log("shiftIndexes _ frameHoverIndex",  frameHoverIndex)

			// if((this.clickEngaged === true)||(this.shiftKey===true)){
			//     return {active: (shiftSelectRangeStart !== -1) && shiftIndexes.includes(index) , selected: this.selectedPointIndexes.has(index) }
			// }
			// else{

			return {active: frameHoverIndex === index, selected: _this.selectedLocalTracks.has(localTrackUuid)}
			// }

		},
		setCardStyle(){
			if(!this.settingsToggled){
				return 'width: 100%;'
			}
			else{
				return 'width:848px;'
			}
		},
		merge(){

			let filteredPoints = this.pointsMutable.filter(r => this.selectedLocalTracks.has(r.localTrackUuid));

			filteredPoints.forEach(r=>{

				this.stagedPoints.push(r);
			})
			this.pointsMutable = this.pointsMutable.filter(r => !this.selectedLocalTracks.has(r.localTrackUuid));
			// this.pointsMutablePage = this.pointsMutablePage.filter(r => !this.selectedLocalTracks.has(r.localTrackUuid));
			this.lastSelectedPointIndex = -1;
			this.selectedPointIndexes = new Set();
			this.selectedLocalTracks = new Set();
		},
		removeSelected(){
			let pointsToRemove = this.stagedPoints.filter(r => this.selectedLocalTracks.has(r.localTrackUuid));
			this.stagedPoints = this.stagedPoints.filter(r => !this.selectedLocalTracks.has(r.localTrackUuid))
			this.pointsMutable = [...this.pointsMutable , ...pointsToRemove]
			console.log(this.stagedPoints.filter(r => this.selectedLocalTracks.has(r.localTrackUuid)));
			this.lastSelectedPointIndex = -1;
			this.selectedPointIndexes = new Set();
			this.selectedLocalTracks = new Set();
		},
		createVideo(){
			const body = {
				videoFileName: this.mergeUuid+'_full_'+this.stagedPoints.length,
				to: this.to,
				from: this.from,
				cameras: "all",
				isBenchMark: false,
				localTracks: this.stagedPoints.map(r=>r.localTrackUuid),
				mergeUuid: this.mergeUuid
			}

			this.lastVideoCreated = body.videoFileName;

			dataAPI
				.post('/video/createVideo', body)
				.then(result=>{
					this.$noty.info(`Succefully created video for ${body.videoFileName}`);
				}).catch(error=>{
					this.$noty.error(`Error creating video for ${body.videoFileName}`);
				})
		},
		imageSelected(point){
			this.$emit('imageSelected', point);
		},
		reset(){
			this.selectedPointIndexes = new Set();
			this.selectedLocalTracks = new Set();
		}
	}
}

</script>

<style scoped>
.local-track-list {
	display: flex;
	flex-wrap: wrap;

	align-items: flex-start;
	padding-right:0px;
	flex-direction: row;
	max-width: 1190px;
}

.local-track-list .local-track {
	box-sizing: border-box;
	/* background: #e0ddd5;
	color: #171e42; */

}
.track-highlight {
	display: inline-block;
	border-style: solid;
	border-top-width: 3px;
	border-bottom-width: 3px;
	margin-top: 0px;
	margin-bottom: 2px;
	background-color: lightgrey;
	border-color: gray;
}

.mask.active {
	background-color: lightgreen;
}

/* .mask {
	background-color: red;
} */

.mask.selected {
	background-color: red;
}

.local-track-list .local-track.col2 {
	flex: 1 0 50%;
}
.local-track-list .local-track.col3 {
	flex: 1 0 33%;
}
.local-track-list .local-track.col4 {
	flex: 1 0 25%;
}
.local-track-list .local-track.col5 {
	flex: 1 0 0%;
}
.local-track-list .local-track.col10 {
	flex: 1 0 1%;
}

/* .local-track:nth-child(2) {
	float: right;
} */
.local-track .frame-item {
	float: left;
}

.local-track-list pre {
	float: left;
	font-size: 12px;
}

.full-frame-container{
	position: fixed;
	top: 8px;
	right: 16px;
	font-size: 18px;
	z-index: 10;
}

.mergeUuidLinking {
	/* display: inline-block; */
	position: relative;
	margin: 0px;
	width: 660px;
	height: 120px;
	overflow:hidden;
}

i:hover {
	color: #007fff;
}

</style>
