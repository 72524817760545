


<v-stage class="image-container" ref="container" :config="forcedSize" @wheel="zoom" >
	
	<v-layer ref="layer" draggable="true" v-if="image" :config="{ scale: { x: stageScale, y: stageScale } }">
		
		<v-image
			:config="{
				image: image,
				height: stageHeight,
				width: stageWidth,
			}"
		/> 
		
		<v-rect
			:config="{
				stroke: 'red',
				strokeWidth: 2,
				x: activeSubframeBounds.left,
				y: activeSubframeBounds.top,
				width: activeSubframeBounds.width,
				height: activeSubframeBounds.height,
			}"
		/>
	</v-layer>
	
</v-stage> 
